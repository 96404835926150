import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import GoogleIcon from "./GoogleIcon";
import { ScaleQCIcon } from "../../components/ScaleQC";
import {
  AspectRatio,
  Autocomplete,
  AutocompleteOption,
  ListItemDecorator,
} from "@mui/joy";

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === "light" ? "dark" : "light");
        onClick?.(event);
      }}
      {...other}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function SignUp() {
  const [countryCode, setCountryCode] = React.useState<string>("US");
  const [countries, setCountries] = React.useState<null | Array<{
    code: string;
    label: string;
    phone: string;
  }>>(null);
  React.useEffect(() => {
    fetch("/auth/countries")
      .then((res) => res.json())
      .then((data) => setCountries(data));
  }, []);

  const handleGoogleAuth = React.useCallback(() => {
    window.location.href = "/login/google";
  }, []);

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <IconButton variant="soft" color="primary" size="sm">
                <Link href="https://scaleqc.com">
                  <ScaleQCIcon />
                </Link>
              </IconButton>
              <Typography level="title-lg">scaleqc.com</Typography>
            </Box>
            <ColorSchemeToggle sx={{ ml: "auto" }} />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Sign up
                </Typography>
                <Typography level="body-sm">
                  Already have an account?{" "}
                  <Link href="/sign-in" level="title-sm">
                    Sign in!
                  </Link>
                </Typography>
                <Typography level="body-sm">
                  <Link level="title-sm" href="/sign-in/forgot-password">
                    Forgot your password?
                  </Link>
                </Typography>
              </Stack>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
                onClick={handleGoogleAuth}
              >
                Continue with Google
              </Button>
              <Typography level="body-sm">
                Sign up quickly with your Google account
              </Typography>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector("light")]: {
                  color: { xs: "#FFF", md: "text.tertiary" },
                },
              })}
            >
              or
            </Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form action="/auth/register">
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                  />
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="password" />
                  <Typography level="body-sm">
                    Use at least 8 characters, including letters and numbers
                  </Typography>
                </FormControl>
                <Stack direction="row">
                  <FormControl required>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                    />
                  </FormControl>
                  <FormControl required>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                    />
                  </FormControl>
                </Stack>

                <FormControl required>
                  <FormLabel>Country</FormLabel>
                  <input
                    type="hidden"
                    name="country_code"
                    value={countryCode}
                  />
                  {countries !== null && (
                    <Autocomplete
                      onChange={(e, val) => {
                        setCountryCode(val!.code);
                      }}
                      getOptionKey={(o) => o.code}
                      name="country"
                      autoHighlight
                      isOptionEqualToValue={(option, value) =>
                        option.code === value.code
                      }
                      defaultValue={{
                        code: "US",
                        label: "United States",
                        phone: "1",
                      }}
                      options={countries}
                      renderOption={(optionProps, option) => (
                        <AutocompleteOption {...optionProps}>
                          <ListItemDecorator>
                            <AspectRatio
                              ratio="1"
                              sx={{ minWidth: 20, borderRadius: "50%" }}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                            </AspectRatio>
                          </ListItemDecorator>
                          {option.label}
                          <Typography
                            component="span"
                            textColor="text.tertiary"
                            ml={0.5}
                          >
                            (+{option.phone})
                          </Typography>
                        </AutocompleteOption>
                      )}
                      slotProps={{
                        input: {
                          autoComplete: "new-password", // disable autocomplete and autofill
                        },
                      }}
                    />
                  )}
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    Sign Up Now
                  </Button>
                </Stack>
                <Typography level="body-sm">
                  We respect your privacy. Your data is secure with us.
                </Typography>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © QCSKU {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
          },
        })}
      />
    </CssVarsProvider>
  );
}
