import * as React from "react";
import "./App.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Outlet } from "react-router-dom";
import { onError } from "@apollo/client/link/error";

import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from "@mui/material/styles";
import { BehaviorSubject } from "rxjs";
import { WS } from "./models/ws";
import { LiveProvider } from "./contexts/LiveContext";
import { AppProvider } from "./contexts/AppContext";
import Notifications from "./x-components/Notifications";

const materialTheme = extendMaterialTheme();

const logoutLink = onError((err) => {
  if ((err.networkError as any).statusCode === 401) {
    window.location.href = "/sign-in";
  }
});

const wss$ = new BehaviorSubject<string | null>(null);
const inputWss$ = new BehaviorSubject<string | null>(null);
const ws = new WS(wss$, inputWss$);

ws.connnect();

// wss$.subscribe({
//   next: (data) => {
//     if (data === null) return;

//     const platformEvent = JSON.parse(data as string) as PlatformEvent;

//     // realTimeStore.setState(platformEvent);
//     console.log(
//       `evicting ${(platformEvent.entityType as string).toUpperCase()}:${
//         platformEvent.entityId
//       }`
//     );

//     // apolloCache.evict({
//     //   id: `${capitalizeFirstLetter(platformEvent.entityType as string)}:${
//     //     platformEvent.entityId
//     //   }`,
//     // });
//     // apolloCache.gc();
//   },
// });

export default function App() {
  const [apolloClient, setApolloClient] = React.useState<any>(null);
  React.useEffect(() => {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL as string,
    });

    const apolloCache = new InMemoryCache();

    // persistCache({
    //   cache: apolloCache,
    //   storage: new LocalStorageWrapper(window.localStorage),
    // }).then(() =>
    setApolloClient(
      new ApolloClient({
        cache: apolloCache,
        link: logoutLink.concat(httpLink),
      })
    );
    // );
  }, []);

  if (!apolloClient) return null;

  return (
    <AppProvider>
      <LiveProvider wss$={wss$} inputWss$={inputWss$}>
        <ApolloProvider client={apolloClient}>
          <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
            <CssVarsProvider disableTransitionOnChange>
              <CssBaseline />
              <Outlet />
              <Notifications />
            </CssVarsProvider>
          </MaterialCssVarsProvider>
        </ApolloProvider>
      </LiveProvider>
    </AppProvider>
  );
}
