import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {
  SupplierFragment,
  useInspectionBidListQuery,
} from "../../__generated__/types-and-hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "../../i18n";
import { useTeam } from "./AgencyLayout";
import { DateTime } from "../../components/DateTime";
import { ToggleView } from "./ToggleView";
import { BidCard } from "./BidCard";

export interface ContactsProps {
  contacts: SupplierFragment["contacts"];
}

export interface ContactMethodProps {
  method: string;
  value: string;
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 20,
    editable: false,
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 120,
    editable: false,
    renderCell: ({ value }) => {
      return <span>{value.name}</span>;
    },
  },
  {
    field: "ref",
    headerName: "REF",
    width: 120,
    editable: false,
  },
  {
    field: "serviceType",
    headerName: "Service Type",
    width: 120,
    editable: false,
  },
  {
    field: "serviceDate",
    headerName: "Service Date",
    width: 180,
    editable: true,
    renderCell: ({ value }) => {
      return (
        <span>
          <DateTime value={value.from} />
          -
          <DateTime value={value.to} />
        </span>
      );
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 200,
    editable: false,
    renderCell: ({ value }) => {
      return (
        <span>
          {value?.addressLine1} {value?.addressLine2}
          {value?.city} {value?.state} {value?.country}
        </span>
      );
    },
  },
];

export default function AgencyInspections() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const inspectionBidListQuery = useInspectionBidListQuery({
    variables: { teamId: teamCtx.team.id },
  });

  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          {t("Inspections")} <ToggleView />
        </Typography>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
        }}
      >
        {inspectionBidListQuery.loading && <div>Loading...</div>}
        {inspectionBidListQuery.data &&
          inspectionBidListQuery.data.inspection_bid_list && (
            <DataGrid
              sx={{
                borderRadius: 4,
              }}
              autoHeight
              rows={inspectionBidListQuery.data.inspection_bid_list.bids}
              columns={columns}
              editMode="cell"
              showColumnVerticalBorder
              showCellVerticalBorder
              columnHeaderHeight={32}
              rowHeight={32}
              disableVirtualization={false}
              hideFooterPagination
              hideFooter
            />
          )}
      </Box>
      <AgencyInspectionsCards />
    </>
  );
}

export function AgencyInspectionsCards() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const inspectionBidListQuery = useInspectionBidListQuery({
    variables: { teamId: teamCtx.team.id },
  });

  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          {t("Requested Inspections")} <ToggleView />
        </Typography>
        Filters [Inline] [PSI] [FA] Date [FROM - TO] Country [US CN]
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
          gap: 2,
          p: 2,
        }}
      >
        {inspectionBidListQuery.loading && <div>Loading...</div>}
        {inspectionBidListQuery.data &&
          inspectionBidListQuery.data.inspection_bid_list &&
          inspectionBidListQuery.data.inspection_bid_list.bids.map((bid) => (
            <BidCard key={bid.id} bid={bid!} />
          ))}
      </Box>
    </>
  );
}
