import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton, { MenuButtonProps } from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { useTranslation, useLang } from "../i18n";
import { AspectRatio } from "@mui/joy";

export default function LanguageToggle(props: MenuButtonProps) {
  const { onClick, ...other } = props;
  const t = useTranslation();
  const [lang, setLang] = useLang();

  return (
    <Dropdown>
      <MenuButton
        variant="soft"
        size="sm"
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "50%",
        }}
        {...other}
      >
        {/* <TranslateIcon /> */}
        <AspectRatio
          ratio="1"
          sx={{
            minWidth: 24,
            borderRadius: (other.sx as any["borderRadius"]) ?? "50%",
          }}
        >
          <img
            loading="lazy"
            srcSet={`https://flagcdn.com/w40/${lang
              .toLowerCase()
              .slice(3)}.png 2x`}
            src={`https://flagcdn.com/w20/${lang.toLowerCase().slice(3)}.png`}
            alt={lang}
          />
        </AspectRatio>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "99999",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        <MenuItem
          selected={lang === "en-US"}
          onClick={() => {
            setLang("en-US");
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              loading="lazy"
              srcSet="https://flagcdn.com/w40/us.png 2x"
              src="https://flagcdn.com/w20/us.png"
              alt=""
            />
            <Box sx={{ ml: 1.5 }}>
              <Typography level="title-sm" textColor="text.primary">
                {t("English")}
              </Typography>
              <Typography level="body-xs" textColor="text.tertiary">
                {t("American")}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem
          selected={lang === "zh-CN"}
          onClick={() => {
            setLang("zh-CN");
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              loading="lazy"
              srcSet="https://flagcdn.com/w40/cn.png 2x"
              src="https://flagcdn.com/w20/cn.png"
              alt=""
            />
            <Box sx={{ ml: 1.5 }}>
              <Typography level="title-sm" textColor="text.primary">
                {t("Chinese")}
              </Typography>
              <Typography level="body-xs" textColor="text.tertiary">
                {t("Simplified")}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
