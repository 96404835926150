import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useGetTeamByIdQuery } from "../__generated__/types-and-hooks";
import VerifiedIcon from "@mui/icons-material/Verified";

export interface TeamTooltipProps {
  teamId: number;
  children: React.ReactNode;
}

export function TeamTooltip({ teamId, children }: TeamTooltipProps) {
  const { data, loading, error } = useGetTeamByIdQuery({
    variables: { id: teamId },
  });

  return (
    <Tooltip
      placement="top-end"
      variant="outlined"
      arrow
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 320,
            justifyContent: "center",
            p: 1,
          }}
        >
          {loading && <CircularProgress />}
          {data && (
            <>
              <Link
                underline="always"
                href={`https://scaleqc.com/team/${data.team_by_id?.slug}`}
                color="neutral"
                sx={{ fontSize: "sm" }}
                startDecorator={
                  <Avatar
                    variant="soft"
                    sx={{
                      borderRadius: 4,
                      // maxWidth: "32px",
                      maxHeight: "32px",
                    }}
                    src={data.team_by_id?.picture}
                  ></Avatar>
                }
                endDecorator={
                  !data.team_by_id?.verified && (
                    <Typography color="primary" fontSize={12}>
                      <VerifiedIcon />
                    </Typography>
                  )
                }
              >
                {data.team_by_id?.name}
              </Link>
              <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
                {data.team_by_id?.description}
              </Box>
            </>
          )}
        </Box>
      }
    >
      <Chip
        color="primary"
        disabled={false}
        onClick={function () {}}
        variant="outlined"
      >
        {children}
      </Chip>
    </Tooltip>
  );
}
