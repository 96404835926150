import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import TableChartIcon from "@mui/icons-material/TableChart";
import GridViewIcon from "@mui/icons-material/GridView";

export function ToggleView() {
  const [alignment, setAlignment] = React.useState<string | null>("left");
  return (
    <Sheet
      variant="outlined"
      sx={{ borderRadius: "md", display: "inline-flex", gap: 2, p: 0.5 }}
    >
      <ToggleButtonGroup
        variant="plain"
        spacing={0.5}
        value={alignment}
        onChange={(event, newAlignment) => {
          setAlignment(newAlignment);
        }}
        aria-label="text alignment"
      >
        <IconButton value="left" aria-label="left aligned">
          <TableChartIcon />
        </IconButton>
        <IconButton value="center" aria-label="centered">
          <GridViewIcon />
        </IconButton>
      </ToggleButtonGroup>
    </Sheet>
  );
}
