import { combineLatest, map, Subject } from "rxjs";
import { XTextInput } from "./XTextInput";
import XCountrySelect from "./XCountrySelect";
import { computed } from "../decorators/computed";

export class AddressFormVM {
  public addressLine1$: Subject<string>;
  public addressLine2$: Subject<string>;
  // district
  public city$: Subject<string>;
  public state$: Subject<string>;
  public postalCode$: Subject<string>;
  public country$: Subject<string>;

  constructor(data: {
    addressLine1$: Subject<string>;
    addressLine2$: Subject<string>;
    city$: Subject<string>;
    state$: Subject<string>;
    postalCode$: Subject<string>;
    country$: Subject<string>;
  }) {
    this.addressLine1$ = data.addressLine1$;
    this.addressLine2$ = data.addressLine2$;
    this.city$ = data.city$;
    this.state$ = data.state$;
    this.postalCode$ = data.postalCode$;
    this.country$ = data.country$;
  }

  @computed
  get string$() {
    return combineLatest({
      addressLine1: this.addressLine1$,
      addressLine2: this.addressLine2$,
      city: this.city$,
      state: this.state$,
      postalCode: this.postalCode$,
      country: this.country$,
    }).pipe(
      map((data) => {
        return [
          data.addressLine1,
          data.addressLine2,
          //data.district,
          [data.city, data.state].join(", ") + " " + data.postalCode,
          data.country,
        ].join("\n");
      })
    );
  }
}

export interface XAddressFormProps {
  vm: AddressFormVM;
}

export function XAddressForm({ vm }: XAddressFormProps) {
  return (
    <>
      <XTextInput label="Address Line 1" required val$={vm.addressLine1$} />
      <XTextInput label="Address Line 2" val$={vm.addressLine2$} />
      {/* <XTextInput label="District" required val$={vm.district$} /> */}
      <XTextInput label="State (province)" required val$={vm.state$} />
      <XTextInput label="City" required val$={vm.city$} />
      <XTextInput label="Postal Code" required val$={vm.postalCode$} />
      <XCountrySelect label="Country" required val$={vm.country$} />
    </>
  );
}
