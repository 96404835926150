import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import { ListItemContent, Typography } from "@mui/joy";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { Sku, useSkuListQuery } from "../__generated__/types-and-hooks";

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      {/* <ListItemDecorator>
        <Typography level="body-xs">#{dataSet[1].id}</Typography>
      </ListItemDecorator> */}
      <ListItemContent
        sx={{
          fontSize: "sm",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {dataSet[1]?.sku}
        <Typography
          level="body-xs"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {dataSet[1].name}
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 1000 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface SkuAutocompleteProps {
  label?: string | React.ReactNode;
  teamId: number;
  val$: Subject<Sku | null>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function SkuAutocomplete({ teamId, val$ }: SkuAutocompleteProps) {
  const val = useRxVal(val$);

  const suppliersListQuery = useSkuListQuery({
    variables: { teamId },
  });

  const handleChanges = React.useCallback(
    (e: any, value: Sku | null) => {
      if (value) {
        val$.next(value);
      }
    },
    [val$]
  );

  const isLoading = suppliersListQuery.loading;
  const suppliers = suppliersListQuery.data?.sku_list?.skus;
  if (!suppliers) return null;

  // const filterOptions = (options: any, { inputValue }: any) => matchSorter(options, inputValue);

  return (
    // <FormControl required={required} error={showError && !!error}>
    // {label && <FormLabel>{label}</FormLabel>}
    // {isLoading && <CircularProgress size="sm" />}
    // {suppliers && (
    <Autocomplete
      size="sm"
      sx={{ width: "100%" }}
      value={val ? suppliers.find((s) => s.id === val.id) : null}
      loading={isLoading}
      disableListWrap
      placeholder="Type to search"
      onChange={handleChanges as any}
      slots={{
        listbox: ListboxComponent,
      }}
      options={suppliers}
      groupBy={(option) => option.sku.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.sku}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
    // )}
    // </FormControl>
  );
}
