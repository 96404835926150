import AgencyInspections from "./AgencyInspections";
import AgencyLayout from "./AgencyLayout";

export const agencyRoutes = {
  path: "/agency/:teamSlug",
  element: <AgencyLayout />,
  children: [
    {
      path: "/agency/:teamSlug/inspections",
      element: <AgencyInspections />,
    },
    // {
    //   path: "/agency/:teamSlug/settings",
    //   element: <Settings />,
    //   children: [
    //     {
    //       path: "/agency/:teamSlug/settings",
    //       element: <General />,
    //     },
    //     {
    //       path: "/agency/:teamSlug/settings/general",
    //       element: <General />,
    //     },
    //     {
    //       path: "/agency/:teamSlug/settings/members",
    //       element: (
    //         <>
    //           <Members />
    //           <InviteMemberModal open={false} />
    //         </>
    //       ),
    //     },
    //     {
    //       path: "/agency/:teamSlug/settings/members/invite",
    //       element: (
    //         <>
    //           <Members />
    //           <InviteMemberModal open={true} />
    //         </>
    //       ),
    //     },
    //     {
    //       path: "/agency/:teamSlug/settings/api",
    //       element: (
    //         <>
    //           <API />
    //         </>
    //       ),
    //     },
    //   ],
    // },
  ],
};
