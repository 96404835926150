import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../i18n";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import CategoryIcon from "@mui/icons-material/Category";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { BidServiceTypeForm } from "./bids/BidServiceTypeForm";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { BidCreateVM } from "./bids/BidCreateVM";
import { BehaviorSubject, first, Subject } from "rxjs";
import { BidSupplierForm } from "./bids/BidSupplierForm";
import {
  Supplier,
  useInspectionBidCreateMutation,
} from "../../../__generated__/types-and-hooks";
import { AddressFormVM } from "../../../x-components/XAddressForm";
import { BidSamplingForm } from "./bids/BidSamplingForm";
import { BidServiceReviewForm } from "./bids/BidServiceReviewForm";
import { BidDateForm } from "./bids/BidDateForm";
import { useTeam } from "../TeamLayout";

interface IStep {
  completed: boolean;
  active: boolean;
  icon: ReactNode;
  text: string;
}

const initialSteps: IStep[] = [
  {
    completed: true,
    active: true,
    icon: <TravelExploreIcon />,
    text: "Inspection Type",
  },
  {
    completed: false,
    active: false,
    icon: <WarehouseIcon />,
    text: "Address",
  },
  {
    completed: false,
    active: false,
    icon: <CategoryIcon />,
    text: "Samplings",
  },
  {
    completed: false,
    active: false,
    icon: <DateRangeIcon />,
    text: "Dates & Rates",
  },
  {
    completed: false,
    active: false,
    icon: <CheckCircleRoundedIcon />,
    text: "Review",
  },
];

interface IconStepperProps {
  steps: IStep[];
  onClick: (step: IStep) => void;
}

export function IconStepper({ steps, onClick }: IconStepperProps) {
  return (
    <Stepper
      size="lg"
      sx={{
        width: "100%",
        "--StepIndicator-size": "3rem",
        "--Step-connectorInset": "0px",
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: "primary.300",
            color: "primary.300",
          },
          "&::after": {
            bgcolor: "primary.300",
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: "currentColor",
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: "neutral.outlinedDisabledColor",
        },
      }}
    >
      {steps.map((step, i) => (
        <Step
          onClick={() => onClick(step)}
          key={i}
          //   completed={step.completed}
          orientation="vertical"
          indicator={
            <StepIndicator
              variant="outlined"
              color={step.active ? "success" : "primary"}
            >
              {step.icon}
            </StepIndicator>
          }
        >
          <Typography
            textColor={step.active ? "text.primary" : "text.tertiary"}
            sx={{
              textTransform: "uppercase",
              fontWeight: "lg",
              fontSize: "0.75rem",
              letterSpacing: "0.5px",
            }}
          >
            {step.text}
          </Typography>
        </Step>
      ))}
    </Stepper>
  );
}

export function CreateBid() {
  const t = useTranslation();
  const vm = useMemo(
    () =>
      new BidCreateVM(
        new BehaviorSubject("PSI"),
        new BehaviorSubject<Supplier | null>(null),
        new AddressFormVM({
          addressLine1$: new BehaviorSubject(""),
          addressLine2$: new BehaviorSubject(""),
          city$: new BehaviorSubject(""),
          state$: new BehaviorSubject(""),
          postalCode$: new BehaviorSubject(""),
          country$: new BehaviorSubject("CN"),
        }),
        new BehaviorSubject<string | null>(null),
        new BehaviorSubject<Date | null>(new Date()),
        new BehaviorSubject<Date | null>(new Date())
      ),
    []
  );

  useEffect(() => {
    return () => {
      //   console.log("DESTROY VM!");
      //   vm.destroy();
    };
  }, [vm]);

  const [inspectionBidCreateMutation] = useInspectionBidCreateMutation();

  const [steps, setSteps] = useState(initialSteps);
  const [step, setStep] = useState(0);

  const team = useTeam();

  const handleNextStep = useCallback(() => {
    steps[step].active = false;
    steps[step + 1].active = true;
    setSteps([...steps]);
    setStep(step + 1);
  }, [step, steps]);

  const handleBackStep = useCallback(() => {
    steps[step].active = false;
    steps[step - 1].active = true;
    setSteps([...steps]);
    setStep(step - 1);
  }, [step, steps]);

  const handleSave = useCallback(() => {
    const sub = vm.json$.pipe(first()).subscribe({
      next(json) {
        inspectionBidCreateMutation({
          variables: {
            bid: {
              teamId: team.team.id,
              supplierId: json.supplier?.id!,
              serviceType: json.serviceType,
              serviceDate: {
                from: json.serviceDateStart,
                to: json.serviceDateEnd,
              },
              address: {
                addressLine1: json.addressLine1,
                addressLine2: json.addressLine2,
                city: json.city,
                country: json.country,
                state: json.state,
              },
              notes: json.notes,
            },
          },
        });
      },
    });

    return () => sub.unsubscribe();
  }, [vm, inspectionBidCreateMutation, team]);

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        display: "flex",
        px: 2,
        py: 2,
      }}
    >
      <Card>
        <Box sx={{ mb: 1 }}>
          <IconStepper
            steps={steps}
            onClick={(ss) => {
              steps[step].active = false;
              const i = steps.findIndex((s) => s === ss);
              if (i !== -1) {
                steps[i].active = true;
              }
              setStep(i);
              setSteps([...steps]);
            }}
          />
        </Box>
        <Divider />
        {step === 0 && <BidServiceTypeForm vm={vm} />}
        {step === 1 && <BidSupplierForm vm={vm} />}
        {step === 2 && <BidSamplingForm vm={vm} />}
        {step === 3 && <BidDateForm vm={vm} />}
        {step === 4 && <BidServiceReviewForm vm={vm} />}
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            {step > 0 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="outlined"
                onClick={handleBackStep}
              >
                {t("Back")}
              </Button>
            )}
            {step < steps.length - 1 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="outlined"
                onClick={handleNextStep}
              >
                {t("Next")}
              </Button>
            )}
            {step === steps.length - 1 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="solid"
                onClick={handleSave}
              >
                {t("Save")}
              </Button>
            )}
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}
