import MenuButton from "@mui/joy/MenuButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dropdown from "@mui/joy/Dropdown";
import {
  Avatar,
  ListDivider,
  ListItemDecorator,
  Stack,
  Typography,
} from "@mui/joy";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useNavigate, useParams } from "react-router-dom";
import { useUserMeQuery } from "../__generated__/types-and-hooks";
import VerifiedIcon from "@mui/icons-material/Verified";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function SelectedMenu() {
  const user = useUserMeQuery();
  const navigate = useNavigate();
  const { teamSlug } = useParams();

  // let team = null;
  if (user.data && teamSlug) {
    const edge = user.data.user_me.teamEdges.find(
      ({ team }) => team.slug === teamSlug
    );
    if (edge) {
      // team = edge.team;
    }
  }

  return (
    <Dropdown>
      <MenuButton variant="soft">
        <ArrowDropDownIcon />
      </MenuButton>
      <Menu disablePortal>
        {user.loading && <MenuItem>Loading...</MenuItem>}
        {user.data &&
          user.data.user_me.teamEdges.map(({ team }) => (
            <MenuItem key={team.id} onClick={() => navigate(`/${team.slug}`)}>
              <ListItemDecorator sx={{ color: "inherit" }}>
                <Avatar
                  src={team.picture}
                  sx={{
                    maxWidth: "24px",
                    maxHeight: "24px",
                    borderRadius: 4,
                  }}
                />
              </ListItemDecorator>
              <Stack direction="column">
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: 120,
                  }}
                >
                  {team.name}{" "}
                  {!team.verified ? (
                    <Typography color="primary" fontSize={12}>
                      <VerifiedIcon />
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
                {/* <Typography
                  fontSize={8}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: 120,
                  }}
                >
                  {team.description}
                </Typography> */}
              </Stack>
            </MenuItem>
          ))}
        <ListDivider>browse</ListDivider>
        <MenuItem onClick={() => navigate("/team/")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <TravelExploreIcon />
          </ListItemDecorator>{" "}
          All Teams
        </MenuItem>
        <MenuItem onClick={() => navigate("/team/new")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <GroupAddIcon />
          </ListItemDecorator>{" "}
          Create Team
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
