import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Profile from "./Profile";
import MyTeams from "./MyTeams";

export default function Settings() {
  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          Profile Settings
        </Typography>
      </Box>
      <Tabs
        defaultValue={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            justifyContent: "left",
            [`&& .${tabClasses.root}`]: {
              fontWeight: "500",
              flex: "initial",
              color: "text.tertiary",
              [`&.${tabClasses.selected}`]: {
                bgcolor: "transparent",
                color: "text.primary",
                "&::after": {
                  height: "2px",
                  bgcolor: "primary.500",
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
            Profile
          </Tab>
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
            My Teams
          </Tab>
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
            Preferences
          </Tab>
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={3}>
            Billing
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={1}>
          <MyTeams />
        </TabPanel>
        <TabPanel value={2}>Preferences</TabPanel>
        <TabPanel value={3}>Billing</TabPanel>
      </Tabs>
    </>
  );
}
