import {
  DataEditor,
  GridCell,
  GridCellKind,
  GridColumn,
  Item,
} from "@glideapps/glide-data-grid";
import { useCallback, useState } from "react";
import type { SkuGroup } from "./dataFn";
import { groupCellRenderer } from "./renderers";

export const columns: (GridColumn & { field: string })[] = [
  { field: "id", title: "ID#", width: 80 },
  { field: "sku", title: "SKU", width: 180 },
  { field: "name", title: "Name", width: 180 },
  { field: "description", title: "Description", width: 180 },
  { field: "category", title: "Category", width: 180 },
  { field: "material", title: "Material", width: 180 },
  { field: "asin", title: "ASIN", width: 80 },
  { field: "hs", title: "HS", width: 80 },
  { field: "hts", title: "HTS", width: 80 },
  { field: "upc", title: "UPC", width: 80 },
  { field: "sources", title: "Sources", width: 80 },
];

export interface SkuDataGridProps {
  rows: SkuGroup[];
}

export default function SkuDataGrid({ rows }: SkuDataGridProps) {
  const [resizableColumns, setResizableColumns] = useState(columns);
  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number) => {
      const col = resizableColumns.find(
        (col) => col.field === (column as GridColumn & { field: string }).field
      );
      if (typeof col === "object") {
        (col as any).width = newSize;
        setResizableColumns([...resizableColumns]);
      }
    },
    [resizableColumns]
  );

  const getData = ([col, row]: Item): GridCell => {
    const sku = rows[row];
    switch (columns[col].field) {
      case "id":
        if (sku.children) {
          return {
            kind: GridCellKind.Custom,
            data: {
              kind: "range-cell",
              value: 10,
              min: 1,
              max: 10,
              step: 1,
              label: "label",
              measureLabel: "measureLabel",
              readonly: false,
            },
            allowOverlay: false,
            copyData: sku.sku,
          };
        }

        return {
          kind: GridCellKind.Number,
          data: sku.id,
          allowOverlay: false,
          displayData: sku.id.toString(),
          contentAlign: "right",
        };
      case "name":
        return {
          kind: GridCellKind.Text,
          data: sku.name,
          allowOverlay: false,
          displayData: sku.name,
        };
      case "sku":
        return {
          kind: GridCellKind.Text,
          data: sku.sku,
          allowOverlay: false,
          displayData: sku.sku,
        };
      case "hts":
        return {
          kind: GridCellKind.Text,
          data: sku.hts ?? "",
          allowOverlay: false,
          displayData: sku.hts ?? "NA",
        };
      case "hs":
        return {
          kind: GridCellKind.Text,
          data: sku.hs ?? "",
          allowOverlay: false,
          displayData: sku.hs ?? "NA",
        };
      case "asin":
        return {
          kind: GridCellKind.Text,
          data: sku.asin ?? "",
          allowOverlay: false,
          displayData: sku.asin ?? "NA",
        };
      case "upc":
        return {
          kind: GridCellKind.Text,
          data: sku.upc ?? "",
          allowOverlay: false,
          displayData: sku.upc ?? "NA",
        };
      case "description":
        return {
          kind: GridCellKind.Text,
          data: sku.description ?? "",
          allowOverlay: false,
          displayData: sku.description ?? "NA",
        };
      case "category":
        return {
          kind: GridCellKind.Text,
          data: sku.category ?? "",
          allowOverlay: false,
          displayData: sku.category ?? "NA",
        };
      case "material":
        return {
          kind: GridCellKind.Text,
          data: sku.material ?? "",
          allowOverlay: false,
          displayData: sku.material ?? "NA",
        };
      case "sources":
        return {
          kind: GridCellKind.Text,
          data: sku.sources.map((s) => s.key).join(",") ?? "",
          allowOverlay: false,
          displayData: sku.sources.map((s) => s.key).join(","),
        };
    }
    throw new Error("Not supported");
  };

  return (
    <DataEditor
      width={"auto"}
      getCellContent={getData}
      columns={resizableColumns}
      smoothScrollX
      smoothScrollY
      rows={rows.length}
      freezeColumns={2}
      scaleToRem={true}
      overscrollX={200}
      overscrollY={200}
      onColumnResize={onColumnResize}
      customRenderers={[groupCellRenderer]}
    />
  );
}
