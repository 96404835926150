import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { InspectionBidCardFragment } from "../../__generated__/types-and-hooks";
import { useTranslation } from "../../i18n";
import { DateTime } from "../../components/DateTime";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
} from "@mui/joy";
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import { TeamTooltip } from "../../components/TeamTooltip";
import React, { useCallback } from "react";
import {
  SamplingFormVM,
  XSamplingForm,
} from "../../x-components/XSamplingForm";
import { ServiceTypeIcon } from "../../components/ServiceTypeIcon";

export interface BidCardProps {
  bid: InspectionBidCardFragment;
}

export function BidCard({ bid }: BidCardProps) {
  const [open, setOpen] = React.useState(false);
  const bookmark = useCallback(() => {
    console.log("bid.id", bid.id);
  }, [bid]);

  return (
    <>
      <Card size="lg" variant="outlined" sx={{ boxShadow: "xs" }}>
        <Typography level="title-md">
          <ServiceTypeIcon value={bid.serviceType} /> #REF by{" "}
          <TeamTooltip teamId={bid.brand.id}>{bid.brand.name}</TeamTooltip>
        </Typography>
        <IconButton
          onClick={bookmark}
          aria-label="bookmark"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
        >
          <BookmarkAdd />
        </IconButton>

        <CardContent>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "120px 1fr",
              gap: 1,
              p: 0,
            }}
          >
            <Typography level="body-xs">Service Date(s):</Typography>
            <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
              <DateTime value={bid.serviceDate?.from} /> -{" "}
              <DateTime value={bid.serviceDate?.to} />
            </Typography>

            <Typography level="body-xs">
              {bid.supplier?.kind === "0" ? "Supplier" : "Factory"}:
            </Typography>
            <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
              {bid.supplier?.name}
            </Typography>
            {bid.address && (
              <>
                <Typography level="body-xs">Address:</Typography>
                <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
                  {bid.address?.addressLine1} {bid.address?.addressLine2}
                  {bid.address?.city} {bid.address?.state}{" "}
                  {bid.address?.country}
                </Typography>
              </>
            )}
            {bid.samplings && bid.samplings.length > 0 && (
              <>
                <Typography level="body-xs">SKU:</Typography>
                <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
                  {bid.samplings.length} SKU(s)
                </Typography>
              </>
            )}
          </Box>
        </CardContent>

        <Divider inset="none" />

        <CardActions>
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            width="100%"
          >
            <Button
              variant="outlined"
              size="md"
              color="primary"
              aria-label="Explore Bahamas Islands"
              sx={{ alignSelf: "center", fontWeight: 600 }}
              onClick={() => setOpen(true)}
            >
              View
            </Button>
            <Button
              startDecorator={<CommentIcon />}
              variant="plain"
              size="md"
              aria-label="Explore Bahamas Islands"
              sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
            ></Button>
            <Button
              variant="solid"
              size="md"
              color="primary"
              aria-label="Explore Bahamas Islands"
              sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
            >
              Send Quote
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="title-md">
            #REF <ServiceTypeIcon value={bid.serviceType} />
            by {bid.brand.name}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "120px 1fr",
                gap: 1,
                p: 0,
              }}
            >
              <Typography level="body-xs">Service Date(s):</Typography>
              <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
                <DateTime value={bid.serviceDate?.from} /> -{" "}
                <DateTime value={bid.serviceDate?.to} />
              </Typography>

              <Typography level="body-xs">
                {bid.supplier?.kind === "0" ? "Supplier" : "Factory"}:
              </Typography>
              <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
                {bid.supplier?.name}
              </Typography>
              {bid.address && (
                <>
                  <Typography level="body-xs">Address:</Typography>
                  <Typography sx={{ fontSize: "sm", fontWeight: "sm" }}>
                    {bid.address?.addressLine1} {bid.address?.addressLine2}
                    {bid.address?.city} {bid.address?.state}{" "}
                    {bid.address?.country}
                  </Typography>
                </>
              )}
            </Box>
          </Sheet>
          <XSamplingForm vm={new SamplingFormVM({})} />
        </ModalDialog>
      </Modal>
    </>
  );
}
