import TeamLayout from "./TeamLayout";
import Bids from "./inspections/Bids";
import { CreateBid } from "./inspections/CreateBid";
import Inspections from "./inspections/Inspections";
import API from "./settings/API";
import AnvylConfigurationModal from "./settings/AnvylConfigurationModal";
import General from "./settings/General";
import Integrations from "./settings/Integrations";
import InviteMemberModal from "./settings/InviteMemberModal";
import Members from "./settings/Members";
import Settings from "./settings/Settings";
import Skus from "./skus/Skus";
import Suppliers from "./suppliers/Suppliers";

export const teamRoutes = {
  path: "/:teamSlug",
  element: <TeamLayout />,
  children: [
    {
      path: "/:teamSlug/suppliers",
      element: <Suppliers />,
      children: [],
    },
    {
      path: "/:teamSlug/sku",
      element: <Skus />,
      children: [],
    },
    {
      path: "/:teamSlug/purchase_orders",
      element: <h1>Purchase Orders</h1>,
      children: [],
    },
    {
      path: "/:teamSlug/members",
      element: <h1>Team Members</h1>,
      children: [],
    },
    {
      path: "/:teamSlug/agencies",
      element: <h1>Users</h1>,
      children: [],
    },
    {
      path: "/:teamSlug/inspections",
      element: <Inspections />,
      children: [
        {
          path: "/:teamSlug/inspections",
          element: <Bids />,
        },
        {
          path: "/:teamSlug/inspections/bids",
          element: <Bids />,
        },
        {
          path: "/:teamSlug/inspections/bids/new",
          element: <CreateBid />,
        },
        {
          path: "/:teamSlug/inspections/in-progress",
          element: <div>In-Progress</div>,
        },
        {
          path: "/:teamSlug/inspections/completed",
          element: <div>Completed</div>,
        },
      ],
    },
    {
      path: "/:teamSlug/settings",
      element: <Settings />,
      children: [
        {
          path: "/:teamSlug/settings",
          element: <General />,
        },
        {
          path: "/:teamSlug/settings/general",
          element: <General />,
        },
        {
          path: "/:teamSlug/settings/integrations",
          element: (
            <>
              <Integrations />
              <AnvylConfigurationModal open={false} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/integrations/anvyl",
          element: (
            <>
              <Integrations />
              <AnvylConfigurationModal open={true} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/members",
          element: (
            <>
              <Members />
              <InviteMemberModal open={false} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/members/invite",
          element: (
            <>
              <Members />
              <InviteMemberModal open={true} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/api",
          element: (
            <>
              <API />
            </>
          ),
        },
      ],
    },
  ],
};
