import { groupBy } from "lodash";
import { useSkuListQuery } from "../../../__generated__/types-and-hooks";

export type Sku = NonNullable<
  NonNullable<ReturnType<typeof useSkuListQuery>["data"]>["sku_list"]
>["skus"][0];

export type SkuGroup = Sku & {
  children?: Sku[];
  count?: number;
  collapsed?: boolean;
};

export function groupBySku(skuList: Sku[]) {
  const groups = groupBy(skuList, (sku) => {
    const dashIndex = sku.sku.indexOf("-");
    if (dashIndex !== -1) {
      return sku.sku.substring(0, dashIndex);
    }
    return sku.sku;
  });

  let res: SkuGroup[] = [];
  for (const skuPrefix of Object.keys(groups)) {
    const children = groups[skuPrefix];
    const [sku] = children;
    const group = {
      ...sku,
      sku: skuPrefix,
      count: children.length,
      collapsed: false,
      children,
    };
    if (group.count > 0) {
      group.name = "GROUP";
    }
    res.push(group);
  }
  return res;
}

export function rowsNumber(skuGroups: SkuGroup[]): number {
  let n = 0;
  for (const { count, collapsed } of skuGroups) {
    if (collapsed) n++;
    else n += (count ?? 0) + 1;
  }
  return n;
}

export function flatten(skuGroups: SkuGroup[]): SkuGroup[] {
  return skuGroups.flatMap((group) => {
    if (group.collapsed) return [group];

    return [group, ...(group.children ?? [])];
  });
}
