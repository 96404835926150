import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import MyTeams from "./MyTeams";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

const rows: GridRowsProp = [
  { id: 1, col1: "Hello", col2: "World" },
  { id: 2, col1: "DataGridPro", col2: "is Awesome" },
  { id: 3, col1: "MUI", col2: "is Amazing" },
];

const columns: GridColDef[] = [
  { field: "col1", headerName: "Column 1", width: 150 },
  { field: "col2", headerName: "Column 2", width: 150 },
];

export default function Dashboard() {
  const user = useUserMeQuery();

  if (user.loading) return <div>Loading</div>;
  return (
    <Stack
      spacing={4}
      sx={{
        display: "flex",
        // maxWidth: "800px",
        px: { xs: 0, md: 0 },
        py: { xs: 2, md: 3 },
      }}
    >
      <MyTeams />
      <Typography>Notifications</Typography>
      <DataGrid rows={rows} columns={columns} />;
      <Typography>Assigned to me</Typography>
    </Stack>
  );
}
