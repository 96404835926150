import { Avatar, Badge } from "@mui/joy";
import { useUserByIdQuery } from "../__generated__/types-and-hooks";

export interface UserAvatarProps {
  userId: number;
  size: "sm" | "md" | "lg";
  online?: boolean;
}

export default function UserAvatar({ userId, size, online }: UserAvatarProps) {
  const userByIdQuery = useUserByIdQuery({ variables: { id: userId } });
  if (!userByIdQuery.data?.user_by_id) return null;

  const user = userByIdQuery.data.user_by_id;
  const fullName = user.firstName + " " + user.lastName;

  return (
    <Badge
      color={online ? "success" : "neutral"}
      variant={online ? "solid" : "soft"}
      size="sm"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeInset="4px 4px"
    >
      <Avatar size={size} alt={fullName} src={user.picture} />
    </Badge>
  );
}
