import { Subject, Subscription } from "rxjs";

// const WS_TOKEN_URL = process.env.REACT_APP_WS_TOKEN_URL as string;
const WS_PINT_TIMEOUT = parseInt(
  process.env.REACT_APP_WS_PINT_TIMEOUT as string,
  10
);

export class WS<
  T extends string | ArrayBufferLike | Blob | ArrayBufferView | null
> {
  private socket: WebSocket | null = null;
  private pingIntervalId: NodeJS.Timer | null = null;
  private wssSubscription: Subscription | null = null;

  constructor(public wss$: Subject<T>, public inputWss$: Subject<T>) {}

  handleOpen = () => {
    console.log("WS_PINT_TIMEOUT", WS_PINT_TIMEOUT);
    this.pingIntervalId = setInterval(() => {
      if (this.socket === null) throw new Error("WebSocket instance is null");

      if (this.socket.readyState === 1) {
        this.socket.send("ping");
      }
    }, WS_PINT_TIMEOUT);

    this.wssSubscription = this.inputWss$.subscribe({
      next: (value) => {
        if (value === null) return;

        if (this.socket === null) throw new Error("WebSocket instance is null");

        if (this.socket.readyState === 1) {
          this.socket.send(value);
        }
      },
    });
  };

  handleClose = (event: any) => {
    console.info(event);
    setTimeout(() => this.connnect(), 1000);
  };

  handleMessage = (event: MessageEvent) => {
    // ignore ping-pong messages
    if (event.data === "pong") return;

    this.wss$.next(event.data);
  };

  async connnect() {
    // try {
    //   const res = await fetch(WS_TOKEN_URL);
    //   const { token } = await res.json();
    //   this.socket = new WebSocket(
    //     `${process.env.REACT_APP_WS_BASE_URL}/${token}`
    //   );
    //   this.socket.addEventListener("open", this.handleOpen);
    //   this.socket.addEventListener("close", this.handleClose);
    //   this.socket.addEventListener("message", this.handleMessage);
    // } catch (e) {
    //   if (this.socket) {
    //     this.socket.removeEventListener("open", this.handleOpen);
    //     this.socket.removeEventListener("close", this.handleClose);
    //     this.socket.removeEventListener("message", this.handleMessage);
    //   }
    //   if (this.pingIntervalId) {
    //     clearInterval(this.pingIntervalId);
    //   }
    //   if (this.wssSubscription) {
    //     this.wssSubscription.unsubscribe();
    //   }
    //   setTimeout(() => this.connnect(), 1000);
    // }
  }
}
