import { useContext, useMemo } from "react";
import { LiveContext } from "../contexts/LiveContext";
import { useRxVal } from "./useRx";
import { filter } from "rxjs";
import { PlatformEvent } from "../models/platform-event";

export function useGridLive(teamId: number, gridId: string) {
  const { live } = useContext(LiveContext)!;
  const gridInteractions$ = useMemo(() => {
    return live.gridInteraction$.pipe(
      filter((platformEvent: PlatformEvent & Record<string, string>) =>
        platformEvent.entityId.startsWith(`${teamId}:${gridId}`)
      )
    );
  }, [teamId, gridId, live]);
  return [useRxVal(gridInteractions$), live.publish];
}
