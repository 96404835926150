import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";

import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import { useNavigate, useParams } from "react-router-dom";
import { useTeamInfoQuery } from "../../../__generated__/types-and-hooks";
import { Chip, Option, Select } from "@mui/joy";
import { DateTime } from "../../../components/DateTime";
import Verified from "@mui/icons-material/VerifiedRounded";
import { useGridLive } from "../../../hooks/useGridLive";

const roles = ["Market", "Finance", "Development"];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows: GridRowsProp = [
  {
    id: randomId(),
    name: randomTraderName(),
    age: 25,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 36,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 19,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 28,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 23,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  return <GridToolbarContainer></GridToolbarContainer>;
}

export default function Members() {
  const [, /*interactions*/ setInteractions] = useGridLive(1, "members");
  const navigate = useNavigate();
  const { teamSlug } = useParams();

  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });

  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 180,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 180,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 270,
      editable: false,
      renderCell: (params) => {
        if (params.row.verifiedEmail) {
          return (
            <span>
              <Verified
                color="success"
                fontSize="small"
                sx={{ mr: 1, verticalAlign: "sub" }}
              />
              {params.row.email}
            </span>
          );
        }
        return params.row.email;
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 180,
      editable: false,
      renderCell: ({ value: country }) => {
        return (
          <span>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
              alt=""
            />
            {" " + country.code}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Join Date",
      renderCell: ({ value }) => <DateTime value={new Date(value)} />,
      width: 180,
      editable: false,
    },
    {
      field: "roles",
      headerName: "Roles",
      width: 220,
      editable: true,
      valueOptions: ["OWNER", "ADMIN", "MANAGER"],
      renderCell: ({ value, id, field }) => {
        return (
          <div style={{ position: "relative" }}>
            {value.map((role: string) => (
              <Chip key={role} sx={{ mr: 1 }}>
                {role}
              </Chip>
            ))}
            {/* {(interactions as any)["1"] &&
              (interactions as any)["1"] === `edit-start:${field}:${id}` && (
                <Avatar
                  style={{ position: "absolute", top: 2, right: 0 }}
                  variant="solid"
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  size="sm"
                />
              )} */}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <Select
            multiple
            defaultValue={params.value}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", gap: "0.25rem" }}>
                {selected.map((selectedOption) => (
                  <Chip variant="soft" color="primary">
                    {selectedOption.label}
                  </Chip>
                ))}
              </Box>
            )}
            sx={{ width: "100%" }}
            slotProps={{
              listbox: {
                sx: {
                  width: "100%",
                },
              },
            }}
          >
            <Option value="OWNER">OWNER</Option>
            <Option value="ADMIN">ADMIN</Option>
            <Option value="MANAGER">MANAGER</Option>
          </Select>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        // height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        startIcon={<PersonAddIcon />}
        onClick={() => navigate("invite")}
      >
        Invite
      </Button>
      {teamInfoQuery.data?.team_by_slug && (
        <DataGrid
          rows={teamInfoQuery.data.team_by_slug.userEdges.map((edge) => ({
            roles: edge.roles,
            createdAt: edge.createdAt,
            ...edge.user,
          }))}
          columns={columns}
          editMode="cell"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onCellEditStart={({ id, field, value }) => {
            (setInteractions as any)({
              entityId: `${teamInfoQuery.data?.team_by_slug?.id}:members:${field}:${id}`,
              entityType: `grid`,
              action: "edit-start",
            });
          }}
          onCellEditStop={({ id, field, value }) => {
            (setInteractions as any)({
              entityId: `${teamInfoQuery.data?.team_by_slug?.id}:members:${field}:${id}`,
              entityType: `grid`,
              action: "edit-stop",
            });
          }}
          getCellClassName={(params) => {
            return params.colDef.editable ? "editable-cell" : "";
          }}
          showColumnVerticalBorder
          showCellVerticalBorder
          slots={{
            toolbar: EditToolbar as GridSlots["toolbar"],
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      )}
    </Box>
  );
}
