import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import { Divider } from "@mui/joy";
import FactoryIcon from "@mui/icons-material/FactoryRounded";
import MemoryIcon from "@mui/icons-material/MemoryRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongRounded";
import GroupsIcon from "@mui/icons-material/GroupsRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgentRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "../i18n";

export default function Navigation() {
  const t = useTranslation();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);

  return (
    <List
      size="sm"
      sx={{ "--ListItem-radius": "var(--joy-radius-sm)", "--List-gap": "4px" }}
    >
      <ListItem nested>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "inspections"}
              to={`/${teamSlug}/inspections`}
            >
              <ListItemDecorator>
                <TodayRoundedIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("Inspections")}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "sku"}
              to={`/${teamSlug}/sku`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <MemoryIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("SKU")}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "purchase_orders"}
              to={`/${teamSlug}/purchase_orders`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <ReceiptLongIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("PO")}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "suppliers"}
              to={`/${teamSlug}/suppliers`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <FactoryIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("Suppliers")}</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
      <ListItem nested>
        <Divider>operations</Divider>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "members"}
              to={`/${teamSlug}/members`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <GroupsIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("Members")}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "agencies"}
              to={`/${teamSlug}/agencies`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <SupportAgentIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("Agencies")}</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "settings"}
              to={`/${teamSlug}/settings`}
            >
              <ListItemDecorator sx={{ color: "neutral.500" }}>
                <SettingsIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>{t("Settings")}</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    </List>
  );
}
