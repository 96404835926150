import Stack from "@mui/joy/Stack";
import { BidCreateVM } from "./BidCreateVM";
import { FormControl, FormLabel, Typography } from "@mui/joy";
import IconsRadio from "../../IconsRadio";
import { useTranslation } from "../../../../i18n";
import { useRxVal } from "../../../../hooks/useRx";
import {
  PSIDescription,
  PSIIcon,
  PSILabel,
  PSIValue,
} from "../../../../components/inspections/PSI";
import {
  InlineDescription,
  InlineIcon,
  InlineLabel,
  InlineValue,
} from "../../../../components/inspections/Inline";
import {
  FADescription,
  FAIcon,
  FALabel,
  FAValue,
} from "../../../../components/inspections/FA";

export interface BidServiceTypeFormProps {
  vm: BidCreateVM;
}

export function BidServiceTypeForm({ vm }: BidServiceTypeFormProps) {
  const t = useTranslation();
  const val = useRxVal(vm.serviceType$);
  const options = [
    {
      label: t(PSILabel),
      description: t(PSIDescription),
      value: PSIValue,
      icon: <PSIIcon />,
    },
    {
      label: t(InlineLabel),
      description: t(InlineDescription),
      value: InlineValue,
      icon: <InlineIcon />,
    },
    {
      label: t(FALabel),
      value: FAValue,
      description: t(FADescription),
      icon: <FAIcon />,
    },
  ];

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <Stack spacing={1}>
        <FormControl>
          <FormLabel>
            {t("Service Type")}
            <Typography
              color="neutral"
              level="body-xs"
              sx={{ opacity: "70%" }}
            ></Typography>
          </FormLabel>
          <IconsRadio
            name="team_type"
            options={options}
            value={val}
            onChange={(arg) => vm.serviceType$.next(arg.target.value)}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
}
