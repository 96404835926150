import Stack from "@mui/joy/Stack";
// @ts-ignore
import { BidCreateVM } from "./BidCreateVM";
import {
  SamplingFormVM,
  XSamplingForm,
} from "../../../../x-components/XSamplingForm";

export interface BidSamplingFormProps {
  vm: BidCreateVM;
}

export function BidSamplingForm({ vm }: BidSamplingFormProps) {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <h2>Sampling Set</h2>
      <XSamplingForm vm={new SamplingFormVM({})} />
      <h2>Checklist</h2>
    </Stack>
  );
}
