import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import AgencyNavigation from "../../components/AgencyNavigation";
import TeamSelect from "../../components/TeamSelect";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useTeamInfoQuery } from "../../__generated__/types-and-hooks";
import { CircularProgress } from "@mui/joy";

export interface TeamContext {
  team: Exclude<
    Exclude<
      ReturnType<typeof useTeamInfoQuery>["data"],
      undefined
    >["team_by_slug"],
    undefined | null
  >;
}

export function useTeam() {
  return useOutletContext<TeamContext>();
}

export default function AgencyLayout() {
  const { teamSlug } = useParams();
  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  if (!teamInfoQuery.loading && !teamInfoQuery.data?.team_by_slug) {
    return <h1>404 - Team Not Found</h1>;
  }

  return (
    <>
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <AgencyNavigation />
        </Layout.SideDrawer>
      )}
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: "flex", sm: "none" },
          zIndex: "999",
          bottom: 0,
          position: "fixed",
          width: "100dvw",
          py: 2,
          backgroundColor: "background.body",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/joy-ui/getting-started/templates/email/"
          size="sm"
          startDecorator={<EmailRoundedIcon />}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          Inspection
        </Button>
        <TeamSelect />
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/joy-ui/getting-started/templates/files/"
          size="sm"
          startDecorator={<FolderRoundedIcon />}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          Inspections
        </Button>
      </Stack>
      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: "100vh",
            overflow: "hidden",
          }),
        }}
      >
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.SideNav>
          <AgencyNavigation />
        </Layout.SideNav>
        <Layout.Main sx={{ mb: 10 }}>
          {teamInfoQuery.loading && <CircularProgress size="lg" />}
          {teamInfoQuery.data?.team_by_slug && (
            <Outlet
              context={
                { team: teamInfoQuery.data?.team_by_slug } satisfies TeamContext
              }
            />
          )}
        </Layout.Main>
      </Layout.Root>
    </>
  );
}
