import Stack from "@mui/joy/Stack";
import * as React from "react";
// @ts-ignore
import { BidCreateVM } from "./BidCreateVM";
import { FormControl } from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { XDatePicker } from "../../../../x-components/XDatePicker";
import { XTextArea } from "../../../../x-components/XTextArea";

export interface BidDateFormProps {
  vm: BidCreateVM;
}

export function BidDateForm({ vm }: BidDateFormProps) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        calendarWeekNumberHeaderText: "#",
        calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
      }}
    >
      <Stack direction={"column"} spacing={2} gap={2} sx={{ flexGrow: 1 }}>
        <FormControl required>
          <XDatePicker
            label="Service Date Start"
            size="sm"
            val$={vm.serviceDateStart$}
          />
          <XDatePicker
            label="Service Date End"
            size="sm"
            val$={vm.serviceDateEnd$}
          />
          <XTextArea
            label="Additional Instructions & Notes"
            placeholder=""
            minRows="5"
            val$={vm.notes$}
          />
        </FormControl>
      </Stack>
    </LocalizationProvider>
  );
}
