// @ts-ignore
import { BidCreateVM } from "./BidCreateVM";
import { Box, Typography } from "@mui/joy";
import { useTranslation } from "../../../../i18n";
import { useRxVal } from "../../../../hooks/useRx";

export interface BidServiceReviewFormProps {
  vm: BidCreateVM;
}

export function BidServiceReviewForm({ vm }: BidServiceReviewFormProps) {
  const t = useTranslation();
  const serviceType = useRxVal(vm.serviceType$);
  const supplier = useRxVal(vm.supplier);
  const address = useRxVal(vm.addressVM.string$);

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        "& > *:nth-child(odd)": { color: "text.secondary" },
      }}
    >
      <Typography level="title-sm">{t("Service Type")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {serviceType}
      </Typography>
      <Typography level="title-sm">{t("Supplier")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {supplier?.name}
      </Typography>
      <Typography level="title-sm">{t("Address")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {address}
      </Typography>
      <Typography level="title-sm">{t("Sampling")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        Sampling Table
      </Typography>
      <Typography level="title-sm">{t("Dates")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        Dates
      </Typography>
      <Typography level="title-sm">{t("Documents")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        Documents
      </Typography>
      <Typography level="title-sm">{t("Notes")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        Notes
      </Typography>
    </Box>
  );
}
