import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import OnlineUsersToggle from "../../components/OnlineUsersToggle";
import { Link } from "react-router-dom";
import { Stack } from "@mui/joy";

export interface BottomActionsCardProps {
  team: any;
  roles: any;
}

export function BottomActionsCard({ team, roles }: BottomActionsCardProps) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: 320,
        // to make the card resizable
        overflow: "auto",
        // resize: "horizontal",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar src={team.picture} size="lg" />
        <OnlineUsersToggle />
      </Box>
      <CardContent>
        <Typography level="title-lg">{team.name}</Typography>
        <Typography
          level="body-sm"
          sx={{
            overflow: "auto",
            height: 120,
          }}
        >
          {team.description}
        </Typography>
      </CardContent>
      <CardActions buttonFlex="0 1 120px">
        <IconButton variant="outlined" color="neutral" sx={{ mr: "auto" }}>
          <FavoriteBorder />
        </IconButton>
        <Button
          component={Link}
          to={`/${team.slug}`}
          variant="outlined"
          color="neutral"
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
}

export default function MyTeams() {
  const user = useUserMeQuery();

  return (
    <Stack direction={"row"} overflow={"auto"}>
      {user.data &&
        user.data?.user_me.teamEdges.map(({ team, roles }) => {
          return (
            <Box key={team.id} sx={{ m: 2 }}>
              <BottomActionsCard team={team} roles={roles} />
            </Box>
          );
        })}
      {/* Create New or Join */}
    </Stack>
  );
}
