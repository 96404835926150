import { combineLatest, Subject, Subscription } from "rxjs";
import { Supplier } from "../../../../__generated__/types-and-hooks";
import { AddressFormVM } from "../../../../x-components/XAddressForm";
import { computed } from "../../../../decorators/computed";

export class BidCreateVM {
  private subs: Subscription[] = [];

  constructor(
    public serviceType$: Subject<string>,
    public supplier: Subject<Supplier | null>,
    public addressVM: AddressFormVM, // public samplingsVM:
    public notes$: Subject<string | null>,
    public serviceDateStart$: Subject<Date | null>,
    public serviceDateEnd$: Subject<Date | null>
  ) {
    const sub = this.supplier.subscribe({
      next: (supplier) => {
        if (supplier && supplier.address) {
          console.log("supplier.address", supplier.address);
          const { address } = supplier;
          this.addressVM.addressLine1$.next(address?.address_line1 ?? "");
          this.addressVM.addressLine2$.next(address?.address_line2 ?? "");
          this.addressVM.city$.next(address?.city ?? "");
          this.addressVM.state$.next(address?.state ?? "");
          this.addressVM.postalCode$.next(address?.postal_code ?? "");
          this.addressVM.country$.next(address?.country ?? "");
        }
      },
    });
    this.subs.push(sub);
  }

  @computed
  get json$() {
    return combineLatest({
      serviceType: this.serviceType$,
      supplier: this.supplier,
      notes: this.notes$,
      serviceDateStart: this.serviceDateStart$,
      serviceDateEnd: this.serviceDateEnd$,
      addressLine1: this.addressVM.addressLine1$,
      addressLine2: this.addressVM.addressLine2$,
      city: this.addressVM.city$,
      state: this.addressVM.state$,
      postalCode: this.addressVM.postalCode$,
      country: this.addressVM.country$,
    });
  }

  destroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
