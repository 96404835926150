import * as React from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  useTeamCreateMutation,
  useTeamUpdateMutation,
} from "../../__generated__/types-and-hooks";
import { Chip, CircularProgress } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import IconsRadio from "./IconsRadio";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useTranslation } from "../../i18n";
import { combineLatest, map, Subject } from "rxjs";
import { computed } from "../../decorators/computed";
import { XTextInput } from "../../x-components/XTextInput";
import { XTextArea } from "../../x-components/XTextArea";
import { useNotify } from "../../hooks/useNotify";
import { useRxVal } from "../../hooks/useRx";
import FileUploadButton from "../../components/FileUploadButton";

export class Team$ {
  constructor(
    public id: number,
    public name$: Subject<string>,
    public slug$: Subject<string>,
    public kind$: Subject<"BRAND" | "AGENCY">,
    public picture$: Subject<string>,
    public description$: Subject<string | null>,
    public contacts$: Subject<Record<string, string>[]>
  ) {}

  @computed
  get name$error$() {
    return this.name$.pipe(
      map((name) => {
        if (name === null || name.length === 0) {
          return "Team name is required.";
        }
        return null;
      })
    );
  }

  @computed
  get slug$error$() {
    return this.slug$.pipe(
      map((slug) => {
        if (slug === null || slug.length === 0) {
          return "Team slug is required.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      name: this.name$,
      description: this.description$,
      slug: this.slug$,
      kind: this.kind$,
      picture: this.picture$,
      contacts: this.contacts$,
    });
  }
}

export interface EditTeamProps {
  team$: Team$;
}

export function EditTeam({ team$ }: EditTeamProps) {
  const t = useTranslation();
  const slugRef = React.useRef<HTMLInputElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const [updateTeam] = useTeamUpdateMutation({});
  const [saving, setSaving] = React.useState(false);
  const notify = useNotify();

  const handleCreateTeamChanges = React.useCallback(async () => {
    setSaving(true);
    team$.json$
      .subscribe({
        next: async (data) => {
          setSaving(true);

          try {
            const res = await updateTeam({
              variables: {
                id: team$.id,
                name: data.name,
                description: data.description,
              },
            });

            setSaving(false);
            if (res.errors) {
              notify({ type: "danger", msg: "An error has been occurred." });
            } else {
              notify({
                type: "success",
                msg: "Team configuration has been updated.",
              });
            }
          } catch (e) {
            console.error(e);
            notify({ type: "danger", msg: "An error has been occurred." });
          }
        },
      })
      .unsubscribe();
  }, [team$, notify, updateTeam]);

  const picture = useRxVal(team$.picture$);

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        display: "flex",
        maxWidth: "800px",
        // mx: "auto",
        px: { xs: 1, md: 2 },
        py: { xs: 1, md: 2 },
      }}
    >
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">{t("Team Info")}</Typography>
          <Typography level="body-sm">
            {t(
              "Customize how your team information will apper to the network."
            )}
          </Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={1}>
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, minWidth: 120, borderRadius: "10%" }}
            >
              <img src={picture} alt="" />
            </AspectRatio>
            <FileUploadButton
              entityType="team"
              entityId={`${team$.id}`}
              resource="logo"
              aria-label="Upload"
              size="sm"
              variant="outlined"
              color="neutral"
              component="label"
              sx={{
                position: "absolute",
                left: 100,
                top: 170,
                bgcolor: "background.body",
              }}
            >
              {(progress) => (
                <CircularProgress
                  thickness={progress === 0 ? 0 : 4}
                  value={progress}
                  size="sm"
                >
                  <FileUploadRoundedIcon />
                </CircularProgress>
              )}
            </FileUploadButton>
          </Stack>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormControl>
                <XTextInput
                  required
                  label={
                    <Stack>
                      {t("Team Slug")}
                      <Typography color="neutral" level="body-xs">
                        {t("A unique identifier for your team's dashboard.")}
                      </Typography>
                    </Stack>
                  }
                  slotProps={{ input: { ref: slugRef } }}
                  size="sm"
                  placeholder="slug"
                  startDecorator={
                    <Chip sx={{ borderRadius: 4 }} color="primary">
                      https://scaleqc.com/
                    </Chip>
                  }
                  val$={team$.slug$}
                  error$={team$.slug$error$}
                  showError
                />
              </FormControl>
              <FormControl>
                <XTextInput
                  required
                  label={
                    <Stack>
                      {t("Team Name")}
                      <Typography
                        color="neutral"
                        level="body-xs"
                        sx={{ opacity: "70%" }}
                      >
                        {t(
                          "The name that will be visible to others on ScaleQC."
                        )}
                      </Typography>
                    </Stack>
                  }
                  slotProps={{ input: { ref: nameRef } }}
                  size="sm"
                  placeholder="Name"
                  val$={team$.name$}
                  error$={team$.name$error$}
                  showError
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  {t("Select Team Type")}
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%" }}
                  ></Typography>
                </FormLabel>
                <IconsRadio
                  name="team_type"
                  options={[
                    {
                      label: t("Business"),
                      value: "brand",
                      icon: <StorefrontIcon />,
                    },
                    {
                      label: t("QC Agency"),
                      value: "agency",
                      icon: <EngineeringIcon />,
                    },
                  ]}
                  defaultValue="brand"
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  {t("Describe your team")}
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%" }}
                  >
                    {t("Tell others about your team and what you offer.")}
                  </Typography>
                </FormLabel>
                <XTextArea
                  val$={team$.description$}
                  size="sm"
                  placeholder="Description"
                  minRows={4}
                />
              </FormControl>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <Typography>{t("Contacts")}</Typography>
              <Typography
                color="neutral"
                level="body-xs"
                sx={{ opacity: "70%" }}
              >
                {t(
                  "Add your contact information (website, email, phone, etc.)"
                )}
              </Typography>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <FormControl>
                    <Select defaultValue="phone">
                      <Option value="phone">{t("Phone")}</Option>
                      <Option value="email">{t("Email")}</Option>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <Input
                      readOnly
                      disabled
                      size="sm"
                      type="email"
                      startDecorator={<EmailRoundedIcon />}
                      placeholder="email"
                      defaultValue=""
                      sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button
              loading={saving}
              size="sm"
              variant="solid"
              onClick={handleCreateTeamChanges}
            >
              {t("Save")}
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}

export default function NewTeam() {
  const t = useTranslation();
  const navigate = useNavigate();
  const [createTeam] = useTeamCreateMutation();
  const slugRef = React.useRef<HTMLInputElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);

  const handleCreateTeamChanges = React.useCallback(async () => {
    await createTeam({
      variables: {
        slug: slugRef.current?.value ?? "",
        name: nameRef.current?.value ?? "",
        kind: "BRAND",
      },
      update: () => {
        navigate("/" + slugRef.current?.value);
      },
    });

    // show snackbar
  }, [slugRef, nameRef, createTeam, navigate]);

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        display: "flex",
        maxWidth: "800px",
        mx: "auto",
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">{t("Team Info")}</Typography>
          <Typography level="body-sm">
            {t(
              "Customize how your team information will apper to the network."
            )}
          </Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={1}>
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, minWidth: 120, borderRadius: "10%" }}
            >
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""
              />
            </AspectRatio>
            <IconButton
              aria-label="upload new picture"
              size="sm"
              variant="outlined"
              color="neutral"
              sx={{
                bgcolor: "background.body",
                position: "absolute",
                zIndex: 2,
                borderRadius: "50%",
                left: 100,
                top: 170,
                boxShadow: "sm",
              }}
            >
              <EditRoundedIcon />
            </IconButton>
          </Stack>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormControl>
                <FormLabel>
                  <FormLabel>
                    {t("Team Slug")}
                    <Typography color="neutral" level="body-xs">
                      {t("A unique identifier for your team's dashboard.")}
                    </Typography>
                  </FormLabel>
                </FormLabel>
                <Input
                  slotProps={{ input: { ref: slugRef } }}
                  size="sm"
                  placeholder="slug"
                  defaultValue=""
                  startDecorator={
                    <Chip sx={{ borderRadius: 4 }} color="primary">
                      https://scaleqc.com/
                    </Chip>
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  {t("Team Name")}
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%" }}
                  >
                    {t("The name that will be visible to others on ScaleQC.")}
                  </Typography>
                </FormLabel>
                <Input
                  slotProps={{ input: { ref: nameRef } }}
                  size="sm"
                  placeholder="Name"
                  defaultValue=""
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  {t("Select Team Type")}
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%" }}
                  ></Typography>
                </FormLabel>
                <IconsRadio
                  name="team_type"
                  options={[
                    {
                      label: t("Business"),
                      value: "brand",
                      icon: <StorefrontIcon />,
                    },
                    {
                      label: t("QC Agency"),
                      value: "agency",
                      icon: <EngineeringIcon />,
                    },
                  ]}
                  defaultValue="brand"
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  {t("Describe your team")}
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%" }}
                  >
                    {t("Tell others about your team and what you offer.")}
                  </Typography>
                </FormLabel>
                <Textarea size="sm" placeholder="Description" minRows={4} />
              </FormControl>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <Typography>{t("Contacts")}</Typography>
              <Typography
                color="neutral"
                level="body-xs"
                sx={{ opacity: "70%" }}
              >
                {t(
                  "Add your contact information (website, email, phone, etc.)"
                )}
              </Typography>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <FormControl>
                    <Select defaultValue="phone">
                      <Option value="phone">{t("Phone")}</Option>
                      <Option value="email">{t("Email")}</Option>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <Input
                      readOnly
                      disabled
                      size="sm"
                      type="email"
                      startDecorator={<EmailRoundedIcon />}
                      placeholder="email"
                      defaultValue=""
                      sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button size="sm" variant="solid" onClick={handleCreateTeamChanges}>
              {t("Create")}
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}
