import "@fontsource/inter";
import "./index.css";
import App from "./App";
import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-up/SignUp";
import NextSteps from "./pages/sign-up/NextSteps";
import ProfileSettings from "./pages/profile/Settings";
import NewTeam from "./pages/team/NewTeam";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import MainLayout from "./SingleColumnLayout";
import Dashboard from "./pages/dash/Dashboard";
import { teamRoutes } from "./pages/team/routes";
import { agencyRoutes } from "./pages/agency/routes";
import { TeamInfo } from "./pages/resource/TeamInfo";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },
          { path: "/agency", element: <div>AGENCY</div> },
        ],
      },
      {
        path: "/t",
        element: <MainLayout />,
        children: [
          {
            path: "/t/:teamSlug",
            element: <TeamInfo />,
          },
        ],
      },
      { ...agencyRoutes },
      { ...teamRoutes },
      {
        path: "/team",
        element: <MainLayout />,
        children: [
          {
            path: "/team/new",
            element: <NewTeam />,
          },
          {
            path: "/team/:teamSlug",
            element: <NewTeam />,
          },
        ],
      },
      {
        path: "/profile",
        element: <MainLayout />,
        children: [
          {
            path: "/profile",
            element: <ProfileSettings />,
          },
          {
            path: "/profile/:userId",
            element: <ProfileSettings />,
          },
        ],
      },
    ],
  },
  /** Sign Up/In */
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/sign-up/next-steps",
    element: <NextSteps />,
  },
  {
    path: "/sign-in/forgot-password",
    element: <ForgotPassword />,
  },
]);
