import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { Avatar } from "@mui/joy";
import { useUserByIdQuery } from "../__generated__/types-and-hooks";
import AvatarGroup from "@mui/joy/AvatarGroup";
import UserAvatar from "./UserAvatar";
import { useOnlineUsers } from "../hooks/useOnlineUsers";

export function UserMenuItem({ userId }: { userId: number }) {
  const userByIdQuery = useUserByIdQuery({ variables: { id: userId } });
  if (!userByIdQuery.data?.user_by_id) return null;

  const user = userByIdQuery.data.user_by_id;

  return (
    <MenuItem>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <UserAvatar online userId={userId} size="sm" />
        <Box sx={{ ml: 1.5 }}>
          <Typography level="title-sm" textColor="text.primary">
            {user.firstName} {user.lastName}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
}

export default function OnlineUsersToggle() {
  const online = useOnlineUsers();

  return (
    <Dropdown>
      <MenuButton variant="plain" size="sm">
        <Typography sx={{ marginRight: 1 }}>Team</Typography>
        <AvatarGroup>
          {online.slice(0, 3).map((userId) => (
            <UserAvatar key={userId} userId={userId} size="sm" online />
          ))}
          {online.length > 3 && (
            <Avatar variant="solid" size="sm">
              +{online.length - 3}
            </Avatar>
          )}
        </AvatarGroup>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "99999",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        {online.map((userId) => (
          <UserMenuItem key={userId} userId={userId} />
        ))}
      </Menu>
    </Dropdown>
  );
}
