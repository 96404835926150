import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { useSkuListQuery } from "../../../__generated__/types-and-hooks";
import { Sheet } from "@mui/joy";
import SkuDataGrid from "./SkuDataGrid";
import { flatten, groupBySku } from "./dataFn";

export default function Skus() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const skuListQuery = useSkuListQuery({
    variables: { teamId: teamCtx.team.id },
  });

  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          {t("SKU")}
        </Typography>
      </Box>
      <Sheet
        sx={{
          m: 2,
          borderRadius: "xl",
          height: "calc(100vh - 160px)",
        }}
        variant="outlined"
      >
        {skuListQuery.loading && <div>Loading...</div>}
        {skuListQuery.data && skuListQuery.data.sku_list && (
          <SkuDataGrid
            rows={flatten(groupBySku(skuListQuery.data!.sku_list.skus))}
          />
        )}
      </Sheet>
    </>
  );
}
