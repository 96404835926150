import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import { CircularProgress } from "@mui/joy";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CountrySelector from "../../components/CountrySelector";
import {
  useUserMeQuery,
  useUserUpdateMutation,
} from "../../__generated__/types-and-hooks";
import FileUploadButton from "../../components/FileUploadButton";

export default function Profile() {
  const user = useUserMeQuery();
  const [updateUser] = useUserUpdateMutation();
  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);

  const handleSaveUserChanges = React.useCallback(async () => {
    await updateUser({
      variables: {
        id: user.data?.user_me.id!,
        firstName: firstNameRef.current?.value,
        lastName: lastNameRef.current?.value,
        verifiedEmail: user.data?.user_me.verifiedEmail!,
        picture: user.data?.user_me.picture!,
        bio: user.data?.user_me.bio!,
        timezone: user.data?.user_me.timezone!,
      },
      // update: (cache, { data }) => {
      //   cache.evict({ id: `User:${user.data?.user_me.id!}` });
      // },
    });

    // show snackbar
  }, [firstNameRef, lastNameRef, user]);

  if (user.loading) return <div>Loading</div>;
  return (
    <Stack
      spacing={4}
      sx={{
        display: "flex",
        maxWidth: "800px",
        px: { xs: 0, md: 0 },
        py: { xs: 2, md: 3 },
      }}
    >
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Personal info</Typography>
          <Typography level="body-sm">
            Customize how your profile information will apper to the network.
          </Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={1}>
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
            >
              <img src={user.data?.user_me.picture} loading="lazy" alt="" />
            </AspectRatio>
            {user.data && (
              <FileUploadButton
                entityType="avatar"
                aria-label="Upload"
                size="sm"
                variant="outlined"
                color="neutral"
                component="label"
                sx={{
                  position: "absolute",
                  left: 100,
                  top: 170,
                  bgcolor: "background.body",
                }}
              >
                {(progress) => (
                  <CircularProgress
                    thickness={progress === 0 ? 0 : 4}
                    value={progress}
                    size="sm"
                  >
                    <FileUploadRoundedIcon />
                  </CircularProgress>
                )}
              </FileUploadButton>
            )}
          </Stack>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>Name</FormLabel>
              <FormControl
                sx={{
                  display: { sm: "flex-column", md: "flex-row" },
                  gap: 2,
                }}
              >
                <Input
                  slotProps={{ input: { ref: firstNameRef } }}
                  size="sm"
                  placeholder="First Name"
                  defaultValue={user.data?.user_me.firstName}
                />
                <Input
                  slotProps={{ input: { ref: lastNameRef } }}
                  size="sm"
                  placeholder="Last name"
                  defaultValue={user.data?.user_me.lastName}
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Input size="sm" defaultValue={user.data?.user_me.role} />
              </FormControl>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Email</FormLabel>
                <Input
                  readOnly
                  disabled
                  size="sm"
                  type="email"
                  startDecorator={<EmailRoundedIcon />}
                  placeholder="email"
                  defaultValue={user.data?.user_me.email}
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
            </Stack>
            <div>
              {user.data && (
                <CountrySelector selectedValue={user.data.user_me.country} />
              )}
            </div>
            <div>
              <FormControl sx={{ display: { sm: "contents" } }}>
                <FormLabel>Timezone</FormLabel>
                <Input
                  size="sm"
                  type="timezone"
                  startDecorator={<EmailRoundedIcon />}
                  placeholder="GMT"
                  defaultValue={user.data?.user_me.timezone}
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
            </div>
            <Divider />
            <Stack spacing={1}>
              <Typography>Contacts</Typography>
              <Stack direction="column" spacing={1}>
                {user.data?.user_me.contacts.map((contact, index) => (
                  <Card key={index}>
                    <Box sx={{ mb: 1 }}>
                      <FormControl sx={{ display: { sm: "contents" } }}>
                        <FormLabel>Type</FormLabel>
                        <Select defaultValue="phone">
                          <Option value="phone">Phone</Option>
                          <Option value="email">Email</Option>
                        </Select>
                      </FormControl>
                    </Box>
                  </Card>
                ))}
                <Stack direction="row" spacing={1}>
                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select defaultValue="phone">
                      <Option value="phone">Phone</Option>
                      <Option value="email">Email</Option>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Value</FormLabel>
                    <Input
                      readOnly
                      disabled
                      size="sm"
                      type="email"
                      startDecorator={<EmailRoundedIcon />}
                      placeholder="email"
                      defaultValue={user.data?.user_me.email}
                      sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral">
              Cancel
            </Button>
            {user.data && (
              <Button size="sm" variant="solid" onClick={handleSaveUserChanges}>
                Save
              </Button>
            )}
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}
