import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";

import Layout from "./components/Layout";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import TeamSelect from "./components/TeamSelect";
import { Outlet } from "react-router-dom";

export default function SingleColumnLayout() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <>
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Navigation />
        </Layout.SideDrawer>
      )}
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: "flex", sm: "none" },
          zIndex: "999",
          bottom: 0,
          position: "fixed",
          width: "100dvw",
          py: 2,
          backgroundColor: "background.body",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/joy-ui/getting-started/templates/email/"
          size="sm"
          startDecorator={<EmailRoundedIcon />}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          Left
        </Button>
        <TeamSelect />
        {/* <Button
    variant="plain"
    color="neutral"
    aria-pressed="true"
    component="a"
    href="/joy-ui/getting-started/templates/team/"
    size="sm"
    startDecorator={<PeopleAltRoundedIcon />}
    sx={{ flexDirection: "column", "--Button-gap": 0 }}
  >
    Team
  </Button> */}
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/joy-ui/getting-started/templates/files/"
          size="sm"
          startDecorator={<FolderRoundedIcon />}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          Right
        </Button>
      </Stack>
      <Layout.Root1Col
        sx={{
          ...(drawerOpen && {
            height: "100vh",
            overflow: "hidden",
          }),
        }}
      >
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.Main>
          <Outlet />
        </Layout.Main>
      </Layout.Root1Col>
    </>
  );
}
