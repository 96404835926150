import Stack from "@mui/joy/Stack";
// @ts-ignore
import { BidCreateVM } from "./BidCreateVM";
import { useTranslation } from "../../../../i18n";
import { SupplierAutocomplete } from "./SupplierAutocomplete";
import { useTeam } from "../../TeamLayout";
import { XAddressForm } from "../../../../x-components/XAddressForm";

export interface BidSupplierFormProps {
  vm: BidCreateVM;
}

export function BidSupplierForm({ vm }: BidSupplierFormProps) {
  const teamCtx = useTeam();
  const t = useTranslation();

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <Stack spacing={2} direction="column">
        <SupplierAutocomplete
          required
          label={t("Supplier")}
          teamId={teamCtx.team.id}
          val$={vm.supplier}
        />
        <XAddressForm vm={vm.addressVM} />
      </Stack>
    </Stack>
  );
}
