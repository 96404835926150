import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import { useNavigate, useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CardOverflow, CircularProgress } from "@mui/joy";
import { useTeamInfoQuery } from "../../__generated__/types-and-hooks";

export function TeamInfo() {
  const { teamSlug } = useParams();

  const { data, loading, error } = useTeamInfoQuery({
    variables: { slug: teamSlug! },
  });

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Card orientation="horizontal" size="sm">
      <CardOverflow>
        {/* TODO: Add a banner image */}
        <AspectRatio ratio="1" sx={{ minWidth: 70 }}>
          <img src={data?.team_by_slug?.picture} loading="lazy" alt="" />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography level="title-md">{data?.team_by_slug?.name}</Typography>
        <Typography level="body-xs" id="card-description">
          {data?.team_by_slug?.description}
        </Typography>
        {/* <Typography level="body-xs" aria-describedby="card-description" mb={1}>
          <Link
            href={team?.url ?? "https://scaleqc.com"}
            endDecorator={<OpenInNewIcon />}
          >
            https://scaleqc.com
          </Link>
        </Typography> */}
      </CardContent>
    </Card>
  );
}
