import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import {
  SupplierFragment,
  useSupplierListQuery,
} from "../../../__generated__/types-and-hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FactoryIcon from "@mui/icons-material/Factory";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactsIcon from "@mui/icons-material/Contacts";

export interface ContactsProps {
  contacts: SupplierFragment["contacts"];
}

function Contacts({ contacts }: ContactsProps) {
  if (contacts.length === 0) return null;
  return (
    <Typography startDecorator={<ContactsIcon fontSize="small" />}>
      {contacts.length}
    </Typography>
  );
}

export interface ContactMethodProps {
  method: string;
  value: string;
}

function ContactMethod({ method, value }: ContactMethodProps) {
  if (value === "") return null;

  if (method === "phone") {
    return (
      <Typography
        level="title-sm"
        startDecorator={<ContactPhoneIcon fontSize="small" />}
      >
        {value}
      </Typography>
    );
  }
  if (method === "email") {
    return (
      <Typography
        fontSize={12}
        startDecorator={<ContactMailIcon fontSize="small" />}
      >
        {value}
      </Typography>
    );
  }
  return null;
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 10,
    editable: false,
  },
  {
    field: "kind",
    headerName: "Type",
    width: 10,
    editable: false,
    renderCell: ({ value }) => (
      <span>
        {value === "0" && <CorporateFareIcon fontSize="small" />}
        {value === "1" && <FactoryIcon fontSize="small" />}
      </span>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    editable: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 180,
    editable: false,
    renderCell: ({ value }) => (
      <span>
        {value.city} {value.state} {value.country}
      </span>
    ),
  },
  {
    field: "contactMethods",
    headerName: "Contact Methods",
    width: 180,
    editable: false,
    renderCell: ({ value }) => (
      <span>
        {(value ?? []).map((v: any, index: number) => (
          <ContactMethod method={v.key} value={v.value} />
        ))}
      </span>
    ),
  },
  {
    field: "contacts",
    headerName: "Contacts",
    width: 180,
    editable: false,
    renderCell: ({ value }) => <Contacts contacts={value} />,
  },
  {
    field: "sources",
    headerName: "Source",
    width: 180,
    editable: false,
    renderCell: ({ value }) => (
      <span>{value.map((v: any) => v.key).join(", ")}</span>
    ),
  },
];

export default function Suppliers() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const suppliersListQuery = useSupplierListQuery({
    variables: { teamId: teamCtx.team.id },
  });

  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          {t("Suppliers")}
        </Typography>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
        }}
      >
        {suppliersListQuery.loading && <div>Loading...</div>}
        {suppliersListQuery.data && suppliersListQuery.data.supplier_list && (
          <DataGrid
            autoHeight
            rows={suppliersListQuery.data.supplier_list.suppliers}
            columns={columns}
            editMode="cell"
            showColumnVerticalBorder
            showCellVerticalBorder
            rowHeight={32}
            disableVirtualization={false}
          />
        )}
      </Box>
    </>
  );
}
