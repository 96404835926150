import FactoryIcon from "@mui/icons-material/Factory";
import { useTranslation } from "../i18n";
import { useTeam } from "../pages/team/TeamLayout";
import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  Sampling,
  Sku,
  useSkuListQuery,
} from "../__generated__/types-and-hooks";
import {
  Box,
  Chip,
  Dropdown,
  Option,
  Select,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import Table from "@mui/joy/Table";
import { SkuAutocomplete } from "./SkuAutocomplete";
import { BehaviorSubject } from "rxjs";

export class SamplingFormVM {
  constructor(data: {}) {}
}

export interface XSamplingFormProps {
  vm: SamplingFormVM;
}

export function AQLView({
  aql,
  sampleSize,
  acceptPoint,
  rejectPoint,
}: Sampling["criticalDefects"]) {
  const level = "body-xs";
  return (
    <Table
      borderAxis="bothBetween"
      sx={{ m: 0, p: 0, width: "100%", height: "100%" }}
    >
      <tbody>
        <tr>
          <td>
            <Typography level={level}>
              AQL <Typography fontWeight="lg">{aql}</Typography>
            </Typography>
          </td>
          <td>
            <Typography level={level}>
              Size <Typography fontWeight="lg">{sampleSize}</Typography>
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography level={level}>
              Accept <Typography fontWeight="lg">{acceptPoint}</Typography>
            </Typography>
          </td>
          <td>
            <Typography level={level}>
              Reject <Typography fontWeight="lg">{rejectPoint}</Typography>
            </Typography>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

function SKUViewEditComponent(props: GridRenderEditCellParams) {
  const sku = props.value;
  const ctx = useTeam();

  return (
    <SkuAutocomplete
      teamId={ctx.team.id}
      val$={new BehaviorSubject<Sku | null>(null)}
    />
  );
}

function AQLViewEditComponent(props: GridRenderEditCellParams) {
  const level = "body-xs";
  const { aql, sampleSize, rejectPoint, acceptPoint } =
    props.value as Sampling["criticalDefects"];
  return (
    <Table
      borderAxis="bothBetween"
      sx={{ m: 0, p: 0, width: "100%", height: "100%" }}
    >
      <tbody>
        <tr>
          <td style={{ margin: 0, padding: 0 }}>
            <Select
              variant="plain"
              startDecorator="AQL"
              size="sm"
              defaultValue={aql}
            >
              <Option value={0}>Not Allowed</Option>
              <Option value={0.065}>0.065</Option>
              <Option value={0.1}>0.1</Option>
              <Option value={0.15}>0.15</Option>
              <Option value={0.25}>0.25</Option>
              <Option value={0.4}>0.40</Option>
              <Option value={0.65}>0.65</Option>
              <Option value={1}>1</Option>
              <Option value={1.5}>1.5</Option>
              <Option value={2.5}>2.5</Option>
              <Option value={4}>4</Option>
              <Option value={6.5}>6.5</Option>
            </Select>
          </td>
          <td>
            <Typography level={level}>
              Size <Typography fontWeight="lg">{sampleSize}</Typography>
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography level={level}>
              Accept <Typography fontWeight="lg">{acceptPoint}</Typography>
            </Typography>
          </td>
          <td>
            <Typography level={level}>
              Reject <Typography fontWeight="lg">{rejectPoint}</Typography>
            </Typography>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 10,
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    pinnable: true,
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 180,
    editable: true,
    pinnable: true,
    renderEditCell: SKUViewEditComponent,
  },
  {
    field: "quantity",
    headerName: "QTY",
    width: 80,
    editable: true,
  },
  {
    field: "inspectinLevel",
    headerName: "IL",
    width: 80,
    editable: true,
  },
  {
    field: "criticalDefects",
    headerName: "Critical Defects AQL",
    width: 240,
    editable: true,
    renderCell: ({ value }) => <AQLView {...value} />,
    renderEditCell: AQLViewEditComponent,
  },
  {
    field: "majorDefects",
    headerName: "Major Defects AQL",
    width: 240,
    editable: true,
    renderCell: ({ value }) => <AQLView {...value} />,
    renderEditCell: AQLViewEditComponent,
  },
  {
    field: "minorDefects",
    headerName: "Minor Defects AQL",
    width: 240,
    editable: true,
    renderCell: ({ value }) => <AQLView {...value} />,
    renderEditCell: AQLViewEditComponent,
  },
];

const rows: any[] = [
  {
    id: 1,
    sku: "SKU1",
    quantity: 123456,
    inspectinLevel: "III",
    criticalDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
    majorDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
    minorDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
  },
  {
    id: 2,
  },
];

export function XSamplingForm({ vm }: XSamplingFormProps) {
  const t = useTranslation();
  const teamCtx = useTeam();
  // const skuListQuery = useSkuListQuery({
  //   variables: { teamId: teamCtx.team.id },
  // });

  return (
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      editMode="cell"
      showColumnVerticalBorder
      showCellVerticalBorder
      rowHeight={80}
      disableVirtualization={false}
      disableColumnMenu
      disableColumnFilter
      disableDensitySelector
      disableMultipleRowSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
}
