import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useTranslation } from "../../../i18n";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useTeam } from "../TeamLayout";

export default function Inspections() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const location = useLocation();
  let currentTab = location.pathname.split("/").pop();
  if (currentTab === "inspections") {
    currentTab = "bids";
  }

  return (
    <>
      <Box>
        <Typography level="h2" component="h1" sx={{ m: 2 }}>
          {t("Inspections")}
        </Typography>
      </Box>
      <Tabs
        sx={{
          bgcolor: "transparent",
        }}
        value={currentTab}
      >
        <TabList
          disableUnderline
          sx={{
            m: 1.5,
            mt: 0,
            p: 0.5,
            gap: 0.5,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
        >
          <Tab
            disableIndicator
            component={Link}
            relative="path"
            to={"bids"}
            value="bids"
          >
            {t("Bids")}
          </Tab>
          <Tab
            disableIndicator
            component={Link}
            relative="path"
            to={"scheduled"}
            value="scheduled"
          >
            {t("Scheduled")}
          </Tab>
          <Tab
            disableIndicator
            component={Link}
            relative="path"
            to={"in-progress"}
            value="in-progress"
          >
            {t("In-Progress")}
          </Tab>
          {/* <Tab
            disableIndicator
            component={Link}
            relative="path"
            to={"completed"}
            value="completed"
          >
            {t("Completed")}
          </Tab> */}
        </TabList>
        <Outlet context={teamCtx} />
      </Tabs>
    </>
  );
}
